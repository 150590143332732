import RegisterComponent from './components/authComponents/RegisterComponent';
import LoginCompoent from './components/authComponents/LoginComponent';
import { Route, Routes ,Navigate} from 'react-router-dom';
import Home from './components/Home';
import Missing from './components/commonComponents/Missing';
import Unauthorized from './components/commonComponents/Unauthorized';
import RequireAuth from './components/commonComponents/RequireAuth';
import LayoutComponent from './components/commonComponents/LayoutComponent';
import {Institute, ROLES} from './consts/appilcationConsts'
import DivisionalSecretayPage from './components/divisionalSecretaryComponents/DivisionalSecretayPage';
import MunicipalCouncilPage from './components/municipalCouncilComponents/MunicipalCouncilPage';
import PradhesiyaSabhaPage from './components/pradhesiyaSabhaComponents/pradhesiyaSabhaPage';
import HomeDashboardComponent from './components/commonComponents/HomeDashboardComponent';
import DataListViewComponent from './components/divisionalSecretaryComponents/DataListViewComponent';
import ShowSubmissionDataPage from './components/divisionalSecretaryComponents/ShowSubmissionDataPage';
import PersistLogin from './components/authComponents/PersistLogin'

function App() {
  return (
    <Routes>
    <Route path="/" element={<LayoutComponent />}>
      {/* public routes */}
      <Route index element={<Navigate to="/home" />} /> 

      <Route path="login" element={<LoginCompoent />} />
      <Route path="register" element={<RegisterComponent />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="home" element={<Home />} />

      {/* we want to protect these routes */}
      <Route element={<PersistLogin/>}>
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_DS]} />}>
          <Route path="/IC_DS" element={<DivisionalSecretayPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_MC]} />}>
          <Route path="/IC_MC" element={<MunicipalCouncilPage/>} />
       </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_PDS]} />}>
          <Route path="/IC_PDS" element={<PradhesiyaSabhaPage/>} />
       </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_DS]} />}>
          <Route path="/dashboard" element={<HomeDashboardComponent/>} />
       </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_DS]} />}>
          <Route path="/view-list" element={<DataListViewComponent/>} />
       </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.LEVEL01]} allowedInstitutes={[Institute.IC_DS]} />}>
          <Route path="/view-ds-submission/:formReferance" element={<ShowSubmissionDataPage />} />
        </Route> 
      </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  </Routes>
  );
}

export default App;
