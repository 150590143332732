import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from "../../api/axios";
const LOGIN_URL = "/auth/login";

const LoginCompoent = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard"

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.body.classList.add('loading-indicator');
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.token;
      const roles = response?.data?.userLevel;
      const userId = response?.data?.userId
      const instituteCategoryCode = response?.data?.instituteCategoryCode;
      const instituteCode = response?.data?.instituteCode;
      const securityStamp = response?.data?.securityStamp;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("securityStamp", securityStamp);

      setAuth({ username,userId, password, roles, accessToken,  instituteCategoryCode, instituteCode, securityStamp});
      setUser('');
      setPwd('');
      document.body.classList.remove('loading-indicator');
      navigate(from , { replace: true });
    } catch (err) {
      document.body.classList.remove('loading-indicator');
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist(prev => !prev);
}

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="login-page">
      <h1 >OLC Web Portal</h1>
      <div className="container col-5 p-5 shadow-lg">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h2>Login</h2>
        <form className="form-group" onSubmit={handleSubmit}>
          <label htmlFor="username">Username:</label>
          <input
            type="email"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={username}
            required
            className="form-control"
          />

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            className="form-control"
            onChange={(e) => setPwd(e.target.value)}
            value={password}
            required
          />
          <button className="btn btn-primary mt-3">Sign In</button>
          <div className="persistCheck">
                    <input
                        type="checkbox"
                        id="persist"
                        onChange={togglePersist}
                        checked={persist}
                    />
                    <label htmlFor="persist">Trust This Device</label>
                </div>
        </form>
      </div>
    </div>
  );
};

export default LoginCompoent;
