import React from "react";

function DivisionalSecretaryPartOneComponent({ fields, onFieldChange,isSubmitted  }) {

  const defaultDataSubmittedOn = fields.dataSubmittedOn || new Date().toISOString().slice(0, 10);

  return (
    <div>
      <div className="pb-3 text-center">
        <h4>General Information</h4>
      </div>
      <form className="form-card text-left">
        <div className="container">
          <div className="row">
            <div className="col mb-3">
              <h5>Name of institution</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Name of institution"
                value={fields.institutionName}
                onChange={(e) => onFieldChange("institutionName", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>DS Division</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="DS Division"
                value={fields.dsDivision}
                onChange={(e) => onFieldChange("dsDivision", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>District</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="District"
                value={fields.district}
                onChange={(e) => onFieldChange("district", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Province</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Province"
                value={fields.province}
                onChange={(e) => onFieldChange("province", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Is this a gazetted Bilingual Divisional Secretary</h5>
            </div>
            <div className="col">
              <select
                className="form-select"
                value={fields.isGazetted }
                onChange={(e) => onFieldChange("isGazetted", e.target.value == 'true'? true:false)}
                disabled={isSubmitted} 
              >
                <option selected>--</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Address</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={fields.address}
                onChange={(e) => onFieldChange("address", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Contact No</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Contact No"
                value={fields.contactNo}
                onChange={(e) => onFieldChange("contactNo", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>E-Mail</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="E-Mail"
                value={fields.email}
                onChange={(e) => onFieldChange("email", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Website</h5>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Website"
                value={fields.website}
                onChange={(e) => onFieldChange("website", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Data submitted by</h5>
            </div>
            <div className="col">
              <label className="mt-0">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={fields.submittedByName}
                onChange={(e) => onFieldChange("submittedByName", e.target.value)}
                readOnly={isSubmitted}
              />
              <label>Designation</label>
              <input
                type="text"
                className="form-control"
                placeholder="Designation"
                value={fields.designation}
                onChange={(e) => onFieldChange("designation", e.target.value)}
                readOnly={isSubmitted}
              />
              <label>Contact Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Contact Number"
                value={fields.submittedByContactNo}
                onChange={(e) => onFieldChange("submittedByContactNo", e.target.value)}
                readOnly={isSubmitted}
              />
              <label>E-Mail</label>
              <input
                type="text"
                className="form-control"
                placeholder="E-Mail"
                value={fields.submittedByEmail}
                onChange={(e) => onFieldChange("submittedByEmail", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Data Submitted on</h5>
            </div>
            <div className="col">
              <input
                type="date"
                className="form-control"
                value={fields.dataSubmittedOn}
                onChange={(e) => onFieldChange("dataSubmittedOn", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DivisionalSecretaryPartOneComponent;
