import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";  

const RequireAuth = ({ allowedRoles, allowedInstitutes  }) => {
    const { auth } = useAuth();
    const location = useLocation();

    const isRoleAllowed = auth?.roles?.some((role) => allowedRoles?.includes(role));
    //const isInstituteAllowed = allowedInstitutes?.includes(auth?.instituteCode);
    const isInstituteAllowed = allowedInstitutes == auth?.instituteCategoryCode;

    return isRoleAllowed && isInstituteAllowed ? (
        <Outlet />
      ) : auth?.accessToken ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      );
}

export default RequireAuth;