import React from "react";
import { Toast } from "react-bootstrap";

const ToastMessageComponent = ({ headerMessage, showToast, toastMessage, onClose, background }) => {
  return (
    <Toast
      onClose={onClose}
      show={showToast}
      delay={5000}
      autohide
      className="position-fixed top-0 end-0 m-3 mt-5 p-5 z-10" 
      bg={background}
    >
      <Toast.Header>
        <strong className="me-auto">{headerMessage}</strong>
      </Toast.Header>
      <Toast.Body>{toastMessage}</Toast.Body>
    </Toast>
  );
};



export default ToastMessageComponent