import React from "react";
import NavBarComponent from "./commonComponents/NavBarComponent";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
 
const Home = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="home-container">
      <NavBarComponent />

      <div className="background-image"></div>

      <div className="container my-5" style={{ color: "#9f3d3e" }}>
        <div className="row">
          <div className="col-9 offset-lg-2 text-center shadow-lg p-4 bg-light bg-opacity-50 rounded-1">
            <h1 className="display-4 mb-4">
              <strong>Welcome to OLC WEB PORTAL</strong>{" "}
            </h1>
            <div className="container">
              <div className="row mb-2 font-weight-bold">
                <h5>
                  Welcome to the web-portal for the Assessment of Implementation
                  of Official Languages Policy in Public Institutions This
                  portal is exclusively used by the staff of Officials Language
                  Commission (OLC) of Sri Lanka
                </h5>
              </div>
              <div className="row mb-2 font-weight-bold">
                <h5>
                  රජයේ ආයතනවල රාජ්‍ය භාෂා ප්‍රතිපත්තිය ක්‍රියාත්මක කිරීම පිළිබඳ
                  තක්සේරුවක් පවත්වන වෙබ් අඩවියට සාදරයෙන් පිළිගනිමු මෙම වෙබ්
                  අඩවිය ශ්‍රී ලංකාවේ රාජ්‍ය භාෂා භාෂා කොමිෂන් සභාවේ (OLC) කාර්ය
                  මණ්ඩලය විසින් පමණක් භාවිතා කරනු ලැබේ
                </h5>
              </div>
              <div className="row mb-2 font-weight-bold">
                <h5>
                  பொது நிறுவனங்களில் அரச கரும மொழி கொள்கையை
                  நடைமுறைப்படுத்துவதற்கான மதிப்பீட்டைச் செயற்படுத்தும் இணைய
                  தளத்திற்கு வரவேற்கிறோம் இந்த இணைய தளம் பிரத்தியேகமாக
                  இலங்கையின் அரச கரும மொழி ஆணைக்குழுவின் (OLC) ஊழியர்களால்
                  பயன்படுத்தப்படுகிறது
                </h5>
              </div>
            </div>
            {!auth?.accessToken && (
              <div className="my-4">
                <button
                  className="btn btn-primary mx-2 btn-lg"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
