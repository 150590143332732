import axios from 'axios';

const BASE_URL = document.getElementsByTagName('base')[0].getAttribute('href') + 'api';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Function to add the access token to the Axios headers
const addAccessTokenToHeaders = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    // Handle the case where there is no access token (e.g., user is not authenticated)
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Call this function to add the access token to the headers before making a request
addAccessTokenToHeaders();

export default axiosInstance;

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

// You can also use axiosPrivate for requests that require the access token.
