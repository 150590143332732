import React, { useState,useEffect } from "react";
import NavBarComponent from "./NavBarComponent";
import useAuth from "../../hooks/useAuth";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";


function HomeDashboardComponent() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [isinstituteTypeSelected, setIsinstituteTypeSelected] = useState(false);
  const [selectedInstituteCategory, setSelectedInstituteCategory] = useState('');

  const instituteCategoryCode = "/" + auth?.instituteCategoryCode;

  useEffect(() => {
    console.log("auth state:", auth); // Add this logging statement
    console.log("local storage data:", localStorage); // Add this logging statement
  }, [auth]);

  const handleAddDataClick = () => {
    const submissionFormReference = uuidv4(); // Generate a new GUID
    localStorage.setItem("submissionFormReference", submissionFormReference); // Store the GUID in local storage
  };

  const handleInstituteCategoryChange = (event) => {
    setSelectedInstituteCategory(event.target.value);
    setIsinstituteTypeSelected(event.target.value !== '');
  };

  let selectOptionText = "Select View or Add Data";
  if (selectedInstituteCategory === "") {
    selectOptionText = "Select Institute Category";
  }

  return (
    <>
      <NavBarComponent />
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ paddingTop: "12rem" }}
      >
        <div>
          <div className="text-center display-5">{selectOptionText}</div>
          <div className="row my-3">
            <div className="col">
              <select
                className="form-select"
                value={selectedInstituteCategory}
                onChange={handleInstituteCategoryChange}
              >
                <option value="">Select Institute Category</option>
                <option value="IC_DS">Divisional Secretariat | ප්‍රාදේශීය ලේකම් කාර්යාලය | பிரதேச செயலகம்</option>
                <option value="IC_MC">Municipal Council | මහා නගර සභාව | மாநகர சபை</option>
                <option value="IC_PS">Police Statation | පොලිස් ස්ථානය | காவல் நிலையம்</option>
                <option value="IC_PDS">Pradheshiya saba | ප්‍රාදේශීය සභාව | பிரதேச சபை</option>
                <option value="IC_UC">urban Council | නගර සභාව | நகர சபை</option>
              </select>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary me-3 btn-lg btn-block"
              disabled={!isinstituteTypeSelected}
              onClick={() => navigate("/view-list")}
            >
              <i className="bi bi-clipboard-data">&nbsp; View Data</i>
            </button>
            <button
              className="btn btn-warning btn-lg btn-block"
              disabled={!isinstituteTypeSelected}
              onClick={() => {
                handleAddDataClick();
                //window.location.href = instituteCategoryCode;
                 navigate(instituteCategoryCode);
              }}
            >
              <i className="bi bi-plus-circle pl-4">&nbsp;Add Data</i>{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeDashboardComponent;
