import React from 'react'
import NavBarComponent from '../commonComponents/NavBarComponent'

function MunicipalCouncilPage() {
  return (
    <div>
      <NavBarComponent/>
            <div className="container-fluid px-1 py-5 mx-auto">
      <div className="row d-flex justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-9 col-11 w-50">
          <h3 className="text-center">Language Audit for Municipal Council</h3>
        </div>
      </div>
    </div>
    </div>
  )
}

export default MunicipalCouncilPage