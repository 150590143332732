import React,{useEffect, useState} from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import FullPageSpinnerComponent from './FullPageSpinnerComponent'

function WithLoaderWrapper(WrappedComponent) {
    const WithLoaderWrapper = (props) => {
        const { loading } = useAxiosPrivate();
    
        return (
          <div>
            {loading && <FullPageSpinnerComponent />}
            <WrappedComponent {...props} />
          </div>
        );
      };

      return WithLoaderWrapper;
    
}

export default WithLoaderWrapper