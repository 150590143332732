import React, { useEffect, useState } from "react";
import NavBarComponent from "../commonComponents/NavBarComponent";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import DivisionalSecretaryPartOneComponent from "./DivisionalSecretaryPartOneComponent";
import DivisionalSecretaryPartTwoComponent from "./DivisionalSecretaryPartTwoComponent";
import DivisionalSecretaryPartThreeComponent from "./DivisionalSecretaryPartThreeComponent";
import { ds_questions } from "./consts";
import MCQVisualizationComponent from "./MCQVisualizationComponent";

function ShowSubmissionDataPage() {
  const [dataSectionOne, setDataSectionOne] = useState([]);
  const [dataSectionTwo, setDataSectionTwo] = useState([]);
  const [dataSectionThree, setDataSectionThree] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const { formReferance } = useParams();

  const navigationButtonLabels = [1, 2, 3];
  const [pageNumber, setPageNumber] = useState(1);
  const handlePageChange = (page) => {
    setPageNumber(page);
  };


  useEffect(() => {
    // Load data using post request when component mounts
    const fetchDataSectionOne = async () => {
      try {
        const response = await axiosPrivate.post(
          `/divisionalSecretary/GetPart01Answers?submissionFormReference=${formReferance}`
        );
        setDataSectionOne(response.data);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
    const fetchDataSectionTwo = async () => {
      try {
        const response = await axiosPrivate.post(
          `/divisionalSecretary/GetPart02Answers?submissionFormReference=${formReferance}`
        );
        setDataSectionTwo(response.data);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
    const fetchDataSectionThree = async () => {
      try {
        const response = await axiosPrivate.post(
          `/divisionalSecretary/GetPart03Answers?submissionFormReference=${formReferance}`
        );
        setDataSectionThree(response.data);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
    fetchDataSectionOne();
    fetchDataSectionTwo();
    fetchDataSectionThree();
  }, [formReferance]);

  return (
    <>
      <NavBarComponent />
      <div className="container-fluid px-1 py-5 mx-auto">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 w-50">
            <h3 className="text-center">
              Language Audit for Divisional Secretary Office
            </h3>
            <div className="card">
              {pageNumber === 1 && (
                <DivisionalSecretaryPartOneComponent
                  fields={dataSectionOne}
                  onFieldChange={null}
                  isSubmitted={true}
                />
              )}
              {pageNumber === 2 && (
                <DivisionalSecretaryPartTwoComponent
                  fields={dataSectionTwo}
                  onFieldChange={null}
                  isSubmitted={true}
                />
              )}
              {pageNumber === 3 && (
                <div>
                <MCQVisualizationComponent data ={dataSectionThree}/>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div
                className="btn-group mt-3"
                role="group"
                aria-label="First group"
              >
                {navigationButtonLabels.map((label, index) => (
                  <button
                    key={index} // Use a unique key for each button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handlePageChange(label)}
                  >
                    Section {label}
                  </button>
                ))}
              </div>
              <div
                className="btn-group justify-content-end  mt-3"
                role="group"
                aria-label="Submit group"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowSubmissionDataPage;
