import axios from '../api/axios';
import useAuth from './useAuth';
import { useState } from 'react';

const useRefreshToken = () => {
    const { auth , setAuth } = useAuth();
    const [username, setUser] = useState("");
    const [password, setPwd] = useState("");

    const refresh = async () => {
        const response = await axios.post('/auth/refresh', {
            AccessToken: localStorage.getItem('accessToken'),
            SecurityStamp: localStorage.getItem('securityStamp'),
        }, {
            withCredentials: true
        });
        // setAuth(prev => ({
        //     ...prev,
        //     roles: response.data.userLevel,
        //     accessToken: response.data.token
        // }));

        const accessToken = response?.data?.token;
        const roles = response?.data?.userLevel;
        const userId = response?.data?.userId
        const instituteCategoryCode = response?.data?.instituteCategoryCode;
        const instituteCode = response?.data?.instituteCode;
        const securityStamp = response?.data?.securityStamp;
  
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("securityStamp", securityStamp);
  
        setAuth({ username,userId, password, roles, accessToken,  instituteCategoryCode, instituteCode, securityStamp});

        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;