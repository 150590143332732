import React from "react";

function DivisionalSecretaryPartTwoComponent({
  fields,
  onFieldChange,
  isSubmitted,
}) {
  return (
    <div>
      <div className="pb-3 text-center">
        <h4>Basic Information</h4>
      </div>
      <form className="form-card text-left">
        <div className="container">
          <h4 className="font-italic">1.1 Population</h4>
          <div className="row">
            <div className="col">
              <h5>Type</h5>
            </div>
            <div className="col">
              <h5>Population in numbers</h5>
            </div>
          </div>
          <hr className="m-1" />
          <div className="row mb-3">
            <div className="col">
              <h5>Sinhala</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="population of Sinhala people"
                value={fields.sinhalaPopulation}
                onChange={(e) =>
                  onFieldChange("sinhalaPopulation", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Tamil</h5>
            </div>
            <div className="col">
              <input 
                type="number"
                className="form-control"
                placeholder="population of Tamil people"
                value={fields.tamilPopulation}
                onChange={(e) =>
                  onFieldChange("tamilPopulation", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Muslim</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="population of Muslim people"
                value={fields.muslimPopulation}
                onChange={(e) =>
                  onFieldChange("muslimPopulation", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Others</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="population of people from other ethnicities"
                value={fields.othersPopulation}
                onChange={(e) =>
                  onFieldChange("othersPopulation", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Total</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="total population"
                value={parseInt(fields.sinhalaPopulation) + parseInt(fields.tamilPopulation) + parseInt(fields.muslimPopulation) + parseInt(fields.othersPopulation) }
                onChange={(e) =>
                  onFieldChange("totalPopulation", e.target.value)
                }
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <h4 className="font-italic">
            1.2 Population with language speaking abilities
          </h4>
          <div className="row">
            <div className="col">
              <h5></h5>
            </div>
            <div className="col">
              <h5>Approximate number of population in the area </h5>
            </div>
          </div>
          <hr className="m-1" />
          <div className="row mb-3">
            <div className="col">
              <h5>Sinhala Only</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Sinhala only"
                value={fields.sinhalaOnly}
                onChange={(e) => onFieldChange("sinhalaOnly", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Tamil Only</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Tamil only"
                value={fields.tamilOnly}
                onChange={(e) => onFieldChange("tamilOnly", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>English Only</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" English only"
                value={fields.englishOnly}
                onChange={(e) => onFieldChange("englishOnly", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Sinhala and Tamil speaking</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" both Sinhala and Tamil"
                value={fields.sinhalaAndTamilSpeaking}
                onChange={(e) =>
                  onFieldChange("sinhalaAndTamilSpeaking", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Tamil and English speaking</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" both Tamil and English"
                value={fields.tamilAndEnglishSpeaking}
                onChange={(e) =>
                  onFieldChange("tamilAndEnglishSpeaking", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Sinhala and English speaking</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" both Sinhala and English"
                value={fields.sinhalaAndEnglishSpeaking}
                onChange={(e) =>
                  onFieldChange("sinhalaAndEnglishSpeaking", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Sinhala, Tamil and English speaking</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Sinhala, Tamil, and English"
                value={fields.allThreeSpeaking}
                onChange={(e) =>
                  onFieldChange("allThreeSpeaking", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Any other language speaking</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" other languages"
                value={fields.otherLanguageSpeaking}
                onChange={(e) =>
                  onFieldChange("otherLanguageSpeaking", e.target.value)
                }
                readOnly={isSubmitted}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <h4 className="font-italic">1.3 Staff Category in numbers </h4>
          <div className="row">
            <div className="col">
              <h5></h5>
            </div>
            <div className="col">
              <h5>Number of Staff </h5>
            </div>
          </div>
          <hr className="m-1" />
          <div className="row mb-3">
            <div className="col">
              <h5>Executive / Senior level</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Executive / Senior level"
                value={fields.seniorLevel}
                onChange={(e) => onFieldChange("seniorLevel", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Tertiary level</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Tertiary level"
                value={fields.tertiaryLevel}
                onChange={(e) => onFieldChange("tertiaryLevel", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Secondary level</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Secondary level"
                value={fields.secondayLevel}
                onChange={(e) => onFieldChange("secondayLevel", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>Primary level</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder=" Primary level"
                value={fields.primaryLevel}
                onChange={(e) => onFieldChange("primaryLevel", e.target.value)}
                readOnly={isSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <h5>TOTAL staff</h5>
            </div>
            <div className="col">
              <input
                type="number"
                className="form-control"
                placeholder="total number of staff"
                value={parseInt(fields.seniorLevel)+ parseInt(fields.tertiaryLevel) + parseInt(fields.secondayLevel) + parseInt(fields.primaryLevel)}
                onChange={(e) => onFieldChange("totalStaff", e.target.value)}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <h4 className="font-italic">1.4 Staff category and ethnicities </h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" rowSpan={2}>
                  Staff category
                </th>
                <th scope="col" className="text-center" colSpan={5}>
                  Type of Ethnicity, in % 
                </th>
              </tr>
              <tr>
                <th scope="col">Sinhala</th>
                <th scope="col">Tamil</th>
                <th scope="col">Muslim</th>
                <th scope="col">Others</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Executive / Senior level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Sinhala Executive / Senior level"
                    value={fields.seniorSinhala}
                    onChange={(e) =>
                      onFieldChange("seniorSinhala", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Tamil Executive / Senior level"
                    value={fields.seniorTamil}
                    onChange={(e) =>
                      onFieldChange("seniorTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Muslim Executive / Senior level"
                    value={fields.seniorMuslim}
                    onChange={(e) =>
                      onFieldChange("seniorMuslim", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" staff from other ethnicities at Executive / Senior level"
                    value={fields.seniorOthers}
                    onChange={(e) =>
                      onFieldChange("seniorOthers", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total Executive / Senior level"
                    value={parseFloat(fields.seniorSinhala) + parseFloat(fields.seniorTamil) + parseFloat(fields.seniorMuslim) + parseFloat(fields.seniorOthers)}
                    onChange={(e) =>
                      onFieldChange("seniorTotal", e.target.value)
                    }
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Tertiary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Sinhala Tertiary level"
                    value={fields.tertiarySinhala}
                    onChange={(e) =>
                      onFieldChange("tertiarySinhala", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Tamil Tertiary level"
                    value={fields.tertiaryTamil}
                    onChange={(e) =>
                      onFieldChange("tertiaryTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Muslim Tertiary level"
                    value={fields.tertiaryMuslim}
                    onChange={(e) =>
                      onFieldChange("tertiaryMuslim", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" staff from other ethnicities at Tertiary level"
                    value={fields.tertiaryOthers}
                    onChange={(e) =>
                      onFieldChange("tertiaryOthers", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total Tertiary level"
                    value={parseFloat(fields.tertiarySinhala) + parseFloat(fields.tertiaryTamil) + parseFloat(fields.tertiaryMuslim) + parseFloat(fields.tertiaryOthers)}
                    onChange={(e) =>
                      onFieldChange("tertiaryTotal", e.target.value)
                    }
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Secondary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Sinhala Secondary level"
                    value={fields.secondarySinhala}
                    onChange={(e) =>
                      onFieldChange("secondarySinhala", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Tamil Secondary level"
                    value={fields.secondaryTamil}
                    onChange={(e) =>
                      onFieldChange("secondaryTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Muslim Secondary level"
                    value={fields.secondaryMuslim}
                    onChange={(e) =>
                      onFieldChange("secondaryMuslim", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" staff from other ethnicities at Secondary level"
                    value={fields.secondaryOthers}
                    onChange={(e) =>
                      onFieldChange("secondaryOthers", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total Secondary level"
                    value={parseFloat(fields.secondarySinhala) + parseFloat(fields.secondaryTamil) + parseFloat(fields.secondaryMuslim) + parseFloat(fields.secondaryOthers)}
                    onChange={(e) =>
                      onFieldChange("secondaryTotal", e.target.value)
                    }
                    readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Primary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Sinhala Primary level"
                    value={fields.primarySinhala}
                    onChange={(e) =>
                      onFieldChange("primarySinhala", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Tamil Primary level"
                    value={fields.primaryTamil}
                    onChange={(e) =>
                      onFieldChange("primaryTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" Muslim Primary level"
                    value={fields.primaryMuslim}
                    onChange={(e) =>
                      onFieldChange("primaryMuslim", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=" staff from other ethnicities at Primary level"
                    value={fields.primaryOthers}
                    onChange={(e) =>
                      onFieldChange("primaryOthers", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Total   Primary level"
                    value={parseFloat(fields.primarySinhala) + parseFloat(fields.primaryTamil) + parseFloat(fields.primaryMuslim) + parseFloat(fields.primaryOthers)}
                    onChange={(e) =>
                      onFieldChange("primaryTotal", e.target.value)
                    }
                    readOnly={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container">
          <h4 className="font-italic">1.5 Staff language with language proficiency in % </h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" rowSpan={2}>
                  Staff category
                </th>
              </tr>
              <tr>
                <th scope="col">Sinhala Only</th>
                <th scope="col">Tamil Only</th>
                <th scope="col">Sinhala and Tamil speaking</th>
                <th scope="col">Tamil and English speaking</th>
                <th scope="col">Sinhala and English speaking</th>
                <th scope="col">Sinhala, Tamil and English speaking</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Executive / Senior level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorSinhalaOnly}
                    onChange={(e) =>
                      onFieldChange("seniorSinhalaOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorTamilOnly}
                    onChange={(e) =>
                      onFieldChange("seniorTamilOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorSinhalaAndTamil}
                    onChange={(e) =>
                      onFieldChange("seniorSinhalaAndTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("seniorTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorSinhalaAndEnglish}
                    onChange={(e) =>
                      onFieldChange("seniorSinhalaAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.seniorSinhalaTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("seniorSinhalaTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Tertiary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiarySinhalaOnly}
                    onChange={(e) =>
                      onFieldChange("tertiarySinhalaOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiaryTamilOnly}
                    onChange={(e) =>
                      onFieldChange("tertiaryTamilOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiarySinhalaAndTamil}
                    onChange={(e) =>
                      onFieldChange("tertiarySinhalaAndTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiaryTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("tertiaryTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiarySinhalaAndEnglish}
                    onChange={(e) =>
                      onFieldChange("tertiarySinhalaAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.tertiarySinhalaTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("tertiarySinhalaTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>

              </tr>
              <tr>
                <th scope="row">Secondary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondarySinhalaOnly}
                    onChange={(e) =>
                      onFieldChange("secondarySinhalaOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondaryTamilOnly}
                    onChange={(e) =>
                      onFieldChange("secondaryTamilOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondarySinhalaAndTamil}
                    onChange={(e) =>
                      onFieldChange("secondarySinhalaAndTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondaryTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("secondaryTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondarySinhalaAndEnglish}
                    onChange={(e) =>
                      onFieldChange("secondarySinhalaAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.secondarySinhalaTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("secondarySinhalaTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>

              </tr>
              <tr>
                <th scope="row">Primary level</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primarySinhalaOnly}
                    onChange={(e) =>
                      onFieldChange("primarySinhalaOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primaryTamilOnly}
                    onChange={(e) =>
                      onFieldChange("primaryTamilOnly", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primarySinhalaAndTamil}
                    onChange={(e) =>
                      onFieldChange("primarySinhalaAndTamil", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primaryTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("primaryTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primarySinhalaAndEnglish}
                    onChange={(e) =>
                      onFieldChange("primarySinhalaAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.primarySinhalaTamilAndEnglish}
                    onChange={(e) =>
                      onFieldChange("primarySinhalaTamilAndEnglish", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container">
          <h4 className="font-italic">1.6 Qualifying in Government Language Proficiency Exam </h4>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Total number of staff, with more than 5 years of service</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.noStaffMoreThanFiveYearsExperience}
                    onChange={(e) =>
                      onFieldChange("noStaffMoreThanFiveYearsExperience", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Total number of staff, qualified in language proficiency exam</th>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={fields.noStaffQulifiedLanguageProficiencyExam}
                    onChange={(e) =>
                      onFieldChange("noStaffQulifiedLanguageProficiencyExam", e.target.value)
                    }
                    readOnly={isSubmitted}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default DivisionalSecretaryPartTwoComponent;
