export const ROLES = {
  'LEVEL01' : 'LEVEL01',
  'LEVEL02' : 'LEVEL02',
  'LEVEL03' : 'LEVEL03'
}

export const Institute = {
  'IC_DS' : 'IC_DS',
  'IC_MC' : 'IC_MC',
  'IC_PS' : 'IC_PS',
  'IC_PDS' : 'IC_PDS',
  'IC_UC' : 'IC_UC',
}
