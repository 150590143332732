import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const DivisionalSecretaryPartThreeComponent = ({
  ds_questions,
  partThreeFields,
  partThreeSubmitted,
  handlePartThreeFieldChange,
}) => {
  useEffect(() => {}, [partThreeFields]);

  const [keyIndicatorPage, setKeyIndicatorPage] = useState(1);
  const keyIndicatorsPerPage = 1; // Number of key indicators to display per page

  const getLetterFromIndex = (index) => {
    const letters = "abcdef";
    if (index >= 0 && index < letters.length) {
      return letters[index];
    }
    return null;
  }

  const indexOfLastKeyIndicator = keyIndicatorPage * keyIndicatorsPerPage;
  const indexOfFirstKeyIndicator = indexOfLastKeyIndicator - keyIndicatorsPerPage;
  const currentKeyIndicators = ds_questions.keyIndicators.slice(
    indexOfFirstKeyIndicator,
    indexOfLastKeyIndicator
  );

  return (
    <div>
      {currentKeyIndicators.map((keyIndicator, keyIndicatorIndex) => (
        <div key={keyIndicatorIndex} className="p-3">
          {keyIndicator.questions.map((question, questionIndex) => (
            <Form.Group key={questionIndex} className="mb-3">
              <Form.Label>{question.questionName}</Form.Label>
              <div>
                <p>
                  Selected answer is :{" "}
                  <b>
                    {getLetterFromIndex(
                      partThreeFields[keyIndicator.keyIndicatorCode][questionIndex]
                    )}
                  </b>
                </p>
                {question.options.map((option, optionIndex) => (
                  <Form.Check
                    key={optionIndex}
                    type="radio"
                    label={option}
                    name={`question-${questionIndex}`}
                    checked={
                      partThreeFields[keyIndicator.keyIndicatorCode][
                        questionIndex
                      ] === optionIndex
                    }
                    onChange={() =>
                      handlePartThreeFieldChange(
                        keyIndicator.keyIndicatorCode,
                        questionIndex,
                        optionIndex
                      )
                    }
                    disabled={partThreeSubmitted}
                  />
                ))}
              </div>
            </Form.Group>
          ))}
        </div>
      ))}

      {/* Pagination Buttons */}
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn btn-primary me-2"
          onClick={() => setKeyIndicatorPage((prev) => Math.max(prev - 1, 1))}
          disabled={keyIndicatorPage === 1}
        >
          &lt;&lt;
        </button>
        <button
          className="btn btn-primary"
          onClick={() =>
            setKeyIndicatorPage((prev) => 
              Math.min(
                Math.ceil(ds_questions.keyIndicators.length / keyIndicatorsPerPage),
                prev + 1
              )
            )
          }
          disabled={
            keyIndicatorPage === Math.ceil(ds_questions.keyIndicators.length / keyIndicatorsPerPage)
          }
        >
          &gt; &gt;
        </button>
      </div>
    </div>
  );
};

export default DivisionalSecretaryPartThreeComponent;
