export const ds_questions = {
    "submissionFormReference": "",
    "instituteCategoryCode": "",
    "keyIndicators": [
        {
            "keyIndicatorCode": "DS_A_1_1",
            "keyIndicatorName": "A1.1 Internal signages",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 1,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.1.1 Office Layout",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        " e.ALL three languages used",
                        "f.Not available"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 2,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.1.2 Organisational chart",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 3,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.1.3 Instruction sign boards (used to show directions for different sections in the office) ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 4,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.1.4 Sectional name boards",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 5,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.1.5 Staff name boards",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_A_1_2",
            "keyIndicatorName": "A1.2 External Signages",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 6,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.2.1 Office Name board ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 7,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.2.2 Office direction boards",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 8,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.2.3 Public display boards ( not the content)",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 9,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.2.4 Other external sign 1",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 10,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A1.2.5 Other external sign 2",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_A_2_1",
            "keyIndicatorName": "A2.1 Signage in front office",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 11,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.1.1 Instructions about the services of front office ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 12,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.1.2 Printed materials about front office ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 13,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.1.3 Displayed materials / posters in the front office area ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 14,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.1.4 Staff name boards in front office ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_A_2_2",
            "keyIndicatorName": "A2.2 Other public gathering areas",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 15,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.2.1 Conference Room / Meeting Room",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 16,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.2.2 Public Toilets",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 17,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "A2.2.3 Breast feeding room",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_1_1",
            "keyIndicatorName": "B1.1 Conversation ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 18,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.1.1 Availability of staff to converse in language of choice with visiting citizen at the front office / reception",
                    "options": [
                        "a.No staff",
                        "b.No exclusive arrangement but managed to use available staff with capacity",
                        "c.Trained staff available ONLY at the Front Office",
                        "d.Trained staff available at the Front Office and ALL other sections",
                        "e.All trained staff available at the Front Office and ALL other sections  wear badges for easy identification\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 19,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.1.2 Internal Practice",
                    "options": [
                        "a.ALL staff who can converse in both official languages are identified",
                        "b.A written list of ALL staff who can converse in both official languages is available",
                        "c.ALL staff who can converse in both official languages and English are identified",
                        "d.A written list of ALL staff who can converse in both official languages  and English is available",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 20,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "B1.1.3 Monitoring Mechanism",
                    "options": [
                        "a.Ad-hoc meetings held with relevant staff to assess the staff performance in conversation in citizen in language of choice",
                        "b.A monitoring committee is named to assess the staff performance in conversation in citizen in language of choice",
                        "c.A survey is conducted to assess the staff performance in conversation in citizen in language of choice",
                        "d.Results of the assessment of staff in conversation in citizen in language of choice are shared with the relevant staff",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_1_2",
            "keyIndicatorName": "B1.2  Tele-conversation ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 21,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.2.1 Availability of staff to converse in language of choice with any citizen through telephone",
                    "options": [
                        "a.No staff",
                        "b.No exclusive arrangement but managed to use available staff with capacity",
                        "c.Trained staff available ONLY at the Front Office",
                        "d.Trained staff available at the Front Office and ALL other sections",
                        "e.All trained staff available at the Front Office and ALL other sections  wear badges for easy identification\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 22,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.2.2 Internal Practice in handling tele-conversation in language of choice",
                    "options": [
                        "a.ALL staff who can converse in both official languages through telephone are identified",
                        "b.A written list of ALL staff who can converse in both official languages through telephone is available",
                        "c.ALL staff who can converse in both official languages and English through telephone are identified",
                        "d.A written list of ALL staff who can converse in both official languages and English through telephone is available",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_1_3",
            "keyIndicatorName": "B1.3 Written Communication ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 23,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.3.1 Availability of staff able to response in writing in language of choice",
                    "options": [
                        "a.No staff",
                        "b.No exclusive arrangement but managed to use available staff with capacity",
                        "c.Trained staff available ONLY at the Front Office",
                        "d.Trained staff available at the Front Office and ALL other sections",
                        "e.All trained staff available at the Front Office and ALL other sections  wear badges for easy identification\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 24,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B1.3.2 Internal Practice in handling written communication in language of choice",
                    "options": [
                        "a.ALL staff who can communicate in writing in both official languages through telephone are identified",
                        "b.A written list of ALL staff who can communicate in writing in both official languages through telephone is available",
                        "c.ALL staff who can communicate in writing in both official languages and English through telephone are identified",
                        "d.A written list of ALL staff who can communicate in writing in both official languages  and English through telephone is available",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_2_1",
            "keyIndicatorName": "B2.1 Records and Filing ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 25,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B2.1.1 Instructions related to maintenance of files in DS office ",
                    "options": [
                        "a.Instructions on files are given in ONE language",
                        "b.At least 25% of the files are with instructions given in BOTH official languages",
                        "c.At least 50% of the files are with instructions given in BOTH official languages",
                        "d.At least 75% of the files are with instructions given in BOTH official languages",
                        "e.ALL of the files are with instructions given in BOTH official languages\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 26,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B2.1.2 Registers maintained in the DS office ",
                    "options": [
                        "a.Most of the registers are with instructions given in ONE language",
                        "b.At least 25% of the registers are with instructions given in BOTH official languages",
                        "c.At least 50% of the registers are with instructions given in BOTH official languages",
                        "d.At least 75% of the registers are with instructions given in BOTH official languages",
                        "e.ALL of the registers are with instructions given in BOTH official languages\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 27,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B2.1.3 Letterhead of the DS ",
                    "options": [
                        "a.Letterhead is in ONE language",
                        "b.Second language is partially used in the letter head",
                        "c.Second language is fully used in the letter head",
                        "d.Letter head is with second language in fully and English partially",
                        "e.Letter head is with second language in fully and English fully\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_2_2",
            "keyIndicatorName": "B2.2 Written Materials ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 28,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B2.2.1 Job description of staff in DS office",
                    "options": [
                        "a.Job description of staff is given in ONE language",
                        "b.At least 25% of the job description of the staff is available in BOTH official languages",
                        "c.At least 50% of the job description of the staff is available in BOTH official languages",
                        "d.At least 75% of the job description of the staff is available in BOTH official languages",
                        "e.ALL of the job description of the staff is available in BOTH official languages  \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 29,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B2.2.2 Internal memo issued by the DS office ",
                    "options": [
                        "a.No practice of issuing internal memo",
                        "b.Internal memo is issued in ONE language",
                        "c.At least 25% of the internal memo is issued in BOTH national languages",
                        "d.At least 50% of the internal memo is issued in BOTH national languages",
                        "e.At least 75% of the internal memo is issued in BOTH national languages\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_3_1",
            "keyIndicatorName": "B3.1 Official Correspondences ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 30,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B3.1.1 Tenders are issued in language of choice ",
                    "options": [
                        "a.Tenders are issued only in ONE language",
                        "b.At least 25% of the tenders are issued in BOTH official languages",
                        "c.At least 50% of the tenders are issued in BOTH official languages",
                        "d.At least 75% of the tenders are issued in BOTH official languages",
                        "e.ALL 100% of the tenders are issued in BOTH official languages \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 31,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B3.1.2 Notice on Registration of suppliers ",
                    "options": [
                        "a.Notice on registration of suppliers is NOT published in newspapers for current year",
                        "b.Notice on registration of suppliers published ONLY in one language newspaper.",
                        "c.Notice on registration of suppliers published in BOTH national language newspapers",
                        "d.Notice on registration of suppliers published in BOTH national language newspapers and English newspaper",
                        "e.Notice on registration of suppliers published in BOTH national languages and English newspaper in website\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 32,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B3.1.3 Public Advertisements / notices ",
                    "options": [
                        "a.Public advertisements are issued only in ONE language",
                        "b.At least 25% of the public advertisements are issued in BOTH official languages",
                        "c.At least 50% of the public advertisements are issued in BOTH official languages",
                        "d.At least 75% of the public advertisements are issued in BOTH official languages",
                        "e.ALL 100% of the public advertisements are issued in BOTH official languages\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_B_3_2",
            "keyIndicatorName": "B3.2 Grievance Redress System",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 33,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B3.2.1 Submission of complaints ",
                    "options": [
                        "a.Complaints are accepted in ONE language",
                        "b.Complaints are accepted in both national languages",
                        "c.Complaints are accepted in both national and English languages",
                        "d.Complaints are accepted in both national languages AND through website can be submitted in both national languages",
                        "e.Complaints are accepted in both national and English languages AND through website can be submitted in both national languages\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 34,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "B3.2.2 Complaints responded in language of choice ",
                    "options": [
                        "a.Complaints are responded in ONE language",
                        "b.At least 25% of complaints are responded in both national languages",
                        "c.At least 50% of complaints are responded in both national languages",
                        "d.At least 75% of complaints are responded in both national languages",
                        "e.ALL 100% of complaints are responded in both national languages\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_1_1",
            "keyIndicatorName": "C1.1 Front office staff training",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 35,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C1.1.1 \r\nTraining in language skills by DS office \r\n",
                    "options": [
                        "a.Institution organizes language training using own resources in their premises for staff in periodic manner",
                        "b.Institution sends staff for language training in NILET",
                        "c.Institution sends staff for language training by Official Language Department",
                        "d.Institution sends staff for language training organized by MDTUs",
                        "e.Institution sends staff for language training organized by other NGOs \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 36,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C1.1.2 Good practices in DS office ",
                    "options": [
                        "a.A roster for the staff with bilingual skills is available in written form for serving in front office/reception",
                        "b.A practice of assigning staff with bilingual skills at front office on-call basis",
                        "c.Use of ICT for serving citizens in language of choice",
                        "d.All staff with bilingual skills are given exclusive badges for easy identification",
                        "e.Staff with bilingual skills served in front office/ reception are felicitated for their services  \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_1_2",
            "keyIndicatorName": "C1.2 Language Proficiency",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 37,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C1.2.1 Staff qualified in  language proficiency exam",
                    "options": [
                        "a.Less than 50% of the eligible staff are  qualified in language proficiency exam",
                        "b.More than 50% but less than or equal to 60% of the eligible staff are qualified in language proficiency exam",
                        "c.More than 60% but less than or equal to 70% of the eligible staff are qualified in language proficiency exam",
                        "d.More than 70% but less than or equal to 80% of the eligible staff are qualified in language proficiency exam",
                        "e.More than 80% but less than or equal to 100% of the eligible staff are qualified in language proficiency exam\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 38,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C1.2.2 Language proficiency development programs (DS office designed and organises language proficiency development programs for staff to excel in following languages)",
                    "options": [
                        "a.Sinhala",
                        "b.Tamil",
                        "c.English",
                        "d.Any other language\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_2_1",
            "keyIndicatorName": "C2.1 Language plan of DS",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 39,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C2.1.1 Availability of Language Plan ",
                    "options": [
                        "a.DS has not prepared a language plan",
                        "b.DS has a language plan but didn’t use for implementation",
                        "c.DS has prepared a language plan but implementation is done ad-hoc manner (with no action plan)",
                        "d.DS has a language plan and implementation is done according to an action plan",
                        "e.DS has a language plan with an action plan and implementation is periodically monitored \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 40,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.1.2 Content of language plan(Language plan prepared by the DS consists of following components)",
                    "options": [
                        "a.Objectives",
                        "b.Targets",
                        "c.Action Plan",
                        "d.Responsible staff for implementation",
                        "e.Monitoring and Evaluation Plan",
                        "f.None of above OR No language plan \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 41,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C2.1.3 Reviewing of Language Plan (DS engage in reviewing the language plan in following manner;)",
                    "options": [
                        "a.A staff is appointed for responsible for reviewing the implementation of language plan but no review is done yet",
                        "b.A staff is appointed for reviewing and engage in periodic reviewing",
                        "c.A committee with DS staff is appointed for reviewing but no review is done yet",
                        "d.A committee with DS staff is appointed for reviewing and review is done",
                        "e.None of above OR No language plan \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_2_2",
            "keyIndicatorName": "C2.2 Proactive action",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 42,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C2.2.1 Help-desk ",
                    "options": [
                        "a.No helpdesk setup is available but an exclusive  space is there for citizen to use",
                        "b.Help-desk is there but no staff to serve in language of choice",
                        "c.Help-desk is there but  staff can handle ONLY TWO languages",
                        "d.Help-desk is there and staff can handle all THREE languages",
                        "e.No Help-desk \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 43,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.2.2 Internal practice (DS engages in following practices to ensure that Official Language Policy (OLP) is effectively adopted; )",
                    "options": [
                        "a.An internal policy to facilitate implementation of OLP is prepared",
                        "b.A staff-level committee is functioning to support the implementation of OLP",
                        "c.A list of volunteers in the area who are willing to assist the DS is prepared",
                        "d.DS initiated programs in partnership with CBOs/NGOs to implement OLP",
                        "e.None of above\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 44,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.2.3 Awareness Creation (DS engages in following awareness creation related activities to promote Official Language Policy (OLP))",
                    "options": [
                        "a.Celebration of Official Languages Day on every July 1st",
                        "b.Periodic awareness programs conducted for the staff on OLP",
                        "c.Periodic awareness programs conducted for the citizen on OLP",
                        "d.Social media campaigns conducted on OLP",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_2_3",
            "keyIndicatorName": "C2.3 Resources for Language Rights ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 45,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "C2.3.1 Resource allocation ",
                    "options": [
                        "a.Last year, DS used less Rs 1000 per 1000 population for promoting OLP",
                        "b.Last year, DS used between Rs 1001 – 2000 per 1000 population",
                        "c.Last year, DS used between Rs 2001 – 3000 per 1000 population",
                        "d.Last year, DS used between Rs 3001 – 4000 per 1000 population",
                        "e.None of above\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 46,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.3.2 Resource Mobilisation ",
                    "options": [
                        "NULLa.DS used own resources (funds and facilities) for promoting OLP",
                        "b.DS partnered with private sector to secure resources for promoting OLP",
                        "c.DS partnered with other Government agencies to secure resources to promote OLP",
                        "d.DS partnered with NGOs to secure resources to promote OLP",
                        "e.None of above\r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 47,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.3.3 Facilities  (DS has following facilities for promoting OLP)",
                    "options": [
                        "a.In-house staff for translation of official languages",
                        "b.Software for translation (voice enabled)",
                        "c.Scanner",
                        "d.Photocopier / Duplo machine",
                        "e.None of above\r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_C_2_4",
            "keyIndicatorName": "C2.4 Citizen Engagement ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 48,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.4.1 Advisory Committee (An advisory committee is functioning to support the DS to implement OLP successfully with following members)",
                    "options": [
                        "a.At least one DS office staff representative",
                        "b.At least one citizen representative",
                        "c.At least 50% of women members",
                        "d.At least one private sector representative",
                        "e.At least one academic",
                        "f.No advisory committee or none of above \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 49,
                    "questionOrderIndex": 0,
                    "questionType": "MCH",
                    "questionName": "C2.4.2 Volunteer Participation (DS made arrangements for volunteers to participate in following manner)",
                    "options": [
                        "a.Engage in translation of documents",
                        "b.Perform interpretation in official languages during events",
                        "c.Training of staff in official languages",
                        "d.Awareness creation in OLP",
                        "e.None of above \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_D_1_1",
            "keyIndicatorName": "D1.1 Information Sharing Practices  ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 50,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.1.1 Content of Public Notice Board ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 51,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.1.2 Content of web-site ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 52,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.1.3 Handling of Social media ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 53,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.1.4 Public Address System ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_D_1_2",
            "keyIndicatorName": "D1.2 Administrative Documents / Publications",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 54,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.1 Administrative Report ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 55,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.2 Resource Profile",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 56,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.3 Citizen Charter ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 57,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.4 Statistical Handbook ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 58,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.5 Publication 1",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 59,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.6 Publication 2",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 60,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D1.2.7 Publication 3",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_D_2_1",
            "keyIndicatorName": "D2.1 Applications and Forms  ",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 61,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.1 Content of Public Notice Board ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 62,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.2 countersigning of residency ",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 63,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.3 Issuing of income certificates",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 64,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.4 Issuing Birth/Death/Marriage Certificates",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 65,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.5 Approval to release of compulsory savings",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 66,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.6 Issuing Dry Rations cards",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 67,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.7 Issuing of Income certificate",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 68,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.8 Issuing of assessment certificate",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 69,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.9 Issuing of land license after approval",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 70,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.10 Recommendation of miscellaneous  subsidies",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 71,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.11 Approval of public donations",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 72,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.12 Payments of Samadhi grants",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 73,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.13 Subsidies paid by Social Development Fund",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 74,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.14 Provision of solutions for electricity objections",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 75,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.15 Approval of loans mortgages on land title deed",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 76,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.16 Recommending Electricity Application",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 77,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.17 Issuing of license to transport animals",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 78,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.18 Issuing of timber permit",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 79,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.19 Issuing of senior citizenship Identity Card",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 80,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.20 Transferring of ownership of gift of land",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 81,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.21 Naming of succession  for gifted land",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 82,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.22 Registration of birth after prescribe time",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 83,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.23 Provision of disaster loans",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 84,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.24 Issuing of timber transport license",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 85,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.25 Naming of transfer of license land",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 86,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.26 Issuing of liquor permit",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 87,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.27 Submitting of National Identity Card to Register General Department",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 88,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.28 Releasing of Compulsory Savings",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 89,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.29 Issuing of pension particulars for pensioners",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 90,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.30 Issuing of  railway warrants for pensioners",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 91,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.1.31 Application 31",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                }
            ]
        },
        {
            "keyIndicatorCode": "DS_D_2_2",
            "keyIndicatorName": "D2.2 Guidelines / Brochures",
            "isSubmitted": true,
            "questions": [
                {
                    "questionMappingId": 92,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.1 Brochure 1",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 93,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.2 Brochure 2",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 94,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.3 Brochure 3",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 95,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.4 Brochure 4",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 96,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.5 Brochure 5",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 97,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.6 Brochure 6",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 98,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.7 Brochure 7",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 99,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.8 Brochure 8",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 100,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.9 Brochure 9",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                },
                {
                    "questionMappingId": 101,
                    "questionOrderIndex": 0,
                    "questionType": "MCQ",
                    "questionName": "D2.2.10 Brochure 10",
                    "options": [
                        "a.Only ONE language is used",
                        "b.Other official language is used partially",
                        "c.Other official language is used fully",
                        "d.Other official language is used fully and English is used partially",
                        "e.ALL three languages used",
                        "f.Not available \r\n"
                    ],
                    "answer": []
                }
            ]
        }
    ],
    "userId": "",
    "instituteCode": "",
    "userLevel": "",
    "createOn": null
}