import React from "react";
import { Link } from "react-router-dom";

const Missing = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h1 className="card-title">Oops!</h1>
              <p className="card-text">Page Not Found</p>
              <div className="d-flex justify-content-center">
                <Link to="/" className="btn btn-primary">
                  Visit Our Homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missing;
