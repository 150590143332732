import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FiUser } from "react-icons/fi";

function NavBarComponent() {
  const navigate = useNavigate();
  const logout = useLogout();

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const homeNavigate = async () => {
    if (auth?.accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }
  };

  const { auth } = useAuth();

  return (
    <nav
      className="navbar px-5"
      style={{
        backgroundColor: "#ffd769",
        position: "sticky",
        top: 0,
        zIndex: 100,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to="/" className="navbar-brand">
          <img
            src="/logo.png"
            alt="Logo"
            width="65"
            height="65"
            className="d-inline-block align-top"
            style={{
              borderRadius: "100px",
              marginRight: "10px",
              boxShadow: "0 9px 6px rgba(0, 0, 0, 0.6)",
            }}
          />
          <span
            style={{
              verticalAlign: "middle",
              fontSize: "2rem",
              textShadow: "0 9px 6px rgba(0, 0, 0, 0.3)",
              color: "#9f3d3e",
            }}
          >
            OLC WEB
          </span>
        </Link>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {auth?.accessToken && (
          <button className="btn btn-success" onClick={homeNavigate}>
            <i className="bi bi-house-door-fill"></i> Home
          </button>
        )}
        {auth?.accessToken && (
          <div style={{ marginLeft: "10px" }}>
            <Dropdown show={showDropdown} onToggle={toggleDropdown}>
              <Dropdown.Toggle variant="secondary" id="dropdownMenuLink" className="navbar-dropdown-toggle">
                <FiUser size={24} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={signOut}>
                    Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavBarComponent;
