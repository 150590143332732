import React, { useState } from "react";
import DivisionalSecretaryPartOneComponent from "./DivisionalSecretaryPartOneComponent";
import DivisionalSecretaryPartTwoComponent from "./DivisionalSecretaryPartTwoComponent";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DivisionalSecretaryPartThreeComponent from "./DivisionalSecretaryPartThreeComponent";
import NavBarComponent from "../commonComponents/NavBarComponent";
import useAuth from "../../hooks/useAuth";
import { ds_questions } from "./consts";
import ToastMessageComponent from "../commonComponents/notificationComponents/ToastMessageComponent";

function DivisionalSecretayPage() {
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const [pageNumber, setPageNumber] = useState(1);

  const [showToast, setShowToast] = useState(false);
  const[toastBg, setToastBg] = useState("success");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");

  const navigationButtonLabels = [1, 2, 3];

  const [partOneFields, setPartOneFields] = useState({
    submissionFormReference: localStorage.getItem('submissionFormReference'), 
    institutionName: "",
    dsDivision: "",
    district: "",
    province: "",
    isGazetted: false, 
    address: "",
    contactNo: "",
    email: "",
    website: "",
    submittedByName: "",
    designation: "",
    submittedByContactNo: "",
    submittedByEmail: "",
    dataSubmittedOn: new Date(),
    userId: auth?.userId,
    instituteCode: auth?.instituteCode,
    userLevel: auth?.roles[0], 
  });

  const [partTwoFields, setPartTwoFields] = useState({
    submissionFormReference: localStorage.getItem('submissionFormReference'), 
    sinhalaPopulation: 0,
    tamilPopulation: 0,
    muslimPopulation: 0,
    othersPopulation: 0,
    totalPopulation: 0,
    sinhalaOnly: 0,
    tamilOnly: 0,
    englishOnly: 0,
    sinhalaAndTamilSpeaking: 0,
    tamilAndEnglishSpeaking: 0,
    sinhalaAndEnglishSpeaking: 0,
    allThreeSpeaking: 0,
    otherLanguageSpeaking: 0,
    seniorLevel: 0,
    tertiaryLevel: 0,
    secondayLevel: 0,
    primaryLevel: 0,
    totalStaff: 0,

    seniorSinhala: 0,
    seniorTamil: 0,
    seniorMuslim: 0,
    seniorOthers: 0,
    seniorTotal: 0,

    tertiarySinhala: 0,
    tertiaryTamil: 0,
    tertiaryMuslim: 0,
    tertiaryOthers: 0,
    tertiaryTotal: 0,

    secondarySinhala: 0,
    secondaryTamil: 0,
    secondaryMuslim: 0,
    secondaryOthers: 0,
    secondaryTotal: 0,

    primarySinhala: 0,
    primaryTamil: 0,
    primaryMuslim: 0,
    primaryOthers: 0,
    primaryTotal: 0,
   
    //1.5 Staff language with language proficiency in %  
    seniorSinhalaOnly :0,
    seniorTamilOnly  :0,
    seniorSinhalaAndTamil  :0,
    seniorTamilAndEnglish  :0,
    seniorSinhalaAndEnglish  :0,
    seniorSinhalaTamilAndEnglish  :0,
    tertiarySinhalaOnly  :0,
    tertiaryTamilOnly  :0,
    tertiarySinhalaAndTamil  :0,
    tertiaryTamilAndEnglish  :0,
    tertiarySinhalaAndEnglish  :0,
    tertiarySinhalaTamilAndEnglish  :0,
    secondarySinhalaOnly  :0,
    secondaryTamilOnly  :0,
    secondarySinhalaAndTamil  :0,
    secondaryTamilAndEnglish  :0,
    secondarySinhalaAndEnglish  :0,
    secondarySinhalaTamilAndEnglish  :0,
    primarySinhalaOnly  :0,
    primaryTamilOnly  :0,
    primarySinhalaAndTamil  :0,
    primaryTamilAndEnglish   :0,
    primarySinhalaAndEnglish   :0,
    primarySinhalaTamilAndEnglish   :0,

    //1.6 Qualifying in Government Language Proficiency Exam 
    noStaffMoreThanFiveYearsExperience :0 ,
    noStaffQulifiedLanguageProficiencyExam  :0 ,

    userId: auth?.userId,
    instituteCode: auth?.instituteCode,
    userLevel: auth?.roles[0],
  });

  const initialPartThreeFields = ds_questions.keyIndicators.reduce((acc, keyIndicator) => {
    acc[keyIndicator.keyIndicatorCode] = keyIndicator.questions.map(() => null);
    return acc;
  }, {});

  const [partThreeFields, setPartThreeFields] = useState(
    initialPartThreeFields
  );

  const [partOneSubmitted, setPartOneSubmitted] = useState(false);
  const [partTwoSubmitted, setPartTwoSubmitted] = useState(false);
  const [partThreeSubmitted, setPartThreeSubmitted] = useState(false);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePartOneFieldChange = (fieldName, value) => {
    setPartOneFields({ ...partOneFields, [fieldName]: value });
  };

  const handlePartTwoFieldChange = (fieldName, value) => {
    setPartTwoFields({ ...partTwoFields, [fieldName]: value });
    setPartTwoFields((prevFields) => ({
      ...prevFields,
      totalPopulation: prevFields.sinhalaPopulation + prevFields.tamilPopulation + prevFields.muslimPopulation,
    }));
  };
  
  const handlePartThreeFieldChange = (
    keyIndicatorCode,
    questionIndex,
    optionIndex
  ) => {
    setPartThreeFields((prevFields) => {
      const newState = { ...prevFields };
      newState[keyIndicatorCode] = newState[keyIndicatorCode].map(
        (option, index) => (index === questionIndex ? optionIndex : option)
      );
      return newState;
    });
  };

  const handlePartOneSubmit = async () => {
    setPartOneSubmitted(true);
    setPageNumber(2);

    const dataToSubmit = {
      ...partOneFields,
      // Add any additional fields
    };

    try {
      const response = await axiosPrivate.post(
        "/divisionalSecretary/SubmitPart01",
        dataToSubmit
      );
      console.log("Part One submitted successfully");
      setToastMessage("Part One submitted successfully!");
      setToastBg("success");
      setToastHeader("Success");
      setShowToast(true);
    } catch (error) {
      console.error("Error submitting Part One:", error);
      setToastMessage("Somthing went wrong !");
      setToastHeader("Error");
      setToastBg("danger");
      setShowToast(true);
    }
  };

  const handlePartTwoSubmit = async () => {
    setPartTwoSubmitted(true);
    setPageNumber(3);

    const dataToSubmit = {
      ...partTwoFields,
      // Add any additional fields
    };

    try {
      const response = await axiosPrivate.post( 
        "/divisionalSecretary/SubmitPart02",
        dataToSubmit
      );
      console.log("Part Two submitted successfully");
      setToastMessage("Part Two submitted successfully!");
      setToastBg("success");
      setToastHeader("Success");
      setShowToast(true);
    } catch (error) {
      console.error("Error submitting Part One:", error);
      setToastMessage("Somthing went wrong !");
      setToastHeader("Error");
      setToastBg("danger");
      setShowToast(true);
    }
  };

  const handlePartThreeSubmit = async () => {
    setPartThreeSubmitted(true);
    setPageNumber(1);
  
    // Create the submit model using partThreeFields
    const submitModel = {
      userId: auth?.userId,
      instituteCode: auth?.instituteCode,
      userLevel: auth?.roles[0],
      createOn: "2023-08-01T18:51:28.540Z", 
      submissionFormReference: localStorage.getItem('submissionFormReference'), 
      instituteCategoryCode: auth?.instituteCategoryCode,
      keyIndicators: ds_questions.keyIndicators.map((keyIndicator) => ({
        keyIndicatorCode: keyIndicator.keyIndicatorCode,
        keyIndicatorName: keyIndicator.keyIndicatorName,
        isSubmitted: keyIndicator.isSubmitted,
        questions: keyIndicator.questions.map((question,questionIndex) => ({
          questionMappingId: question.questionMappingId,
          questionOrderIndex: 0,
          questionType: question.questionType,
          questionName: question.question,
          options:[],
          answer: [partThreeFields[keyIndicator.keyIndicatorCode][questionIndex]],
        })),
      })),
    };
  
    try {
      const response = await axiosPrivate.post(
        "/divisionalSecretary/SubmitPart03",
        submitModel
      );
      console.log("Part Three submitted successfully");
      setToastMessage("Part Three submitted successfully!");
      setToastBg("success");
      setToastHeader("Success");
      setShowToast(true);
    } catch (error) {
      console.error("Error submitting Part Three:", error);
      setToastMessage("Somthing went wrong !");
      setToastHeader("Error");
      setToastBg("danger");
      setShowToast(true);
    }
  };

  return (
    <div>
      <NavBarComponent />
      <div className="container-fluid px-1 py-5 mx-auto">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 w-50">
            <h3 className="text-center">
              Language Audit for Divisional Secretary Office
            </h3>
            <div className="card">
              {pageNumber === 1 && (
                <DivisionalSecretaryPartOneComponent
                  fields={partOneFields}
                  onFieldChange={handlePartOneFieldChange}
                  isSubmitted={partOneSubmitted}
                />
              )}
              {pageNumber === 2 && (
                <DivisionalSecretaryPartTwoComponent
                  fields={partTwoFields}
                  onFieldChange={handlePartTwoFieldChange}
                  isSubmitted={partTwoSubmitted}
                />
              )}
              {pageNumber === 3 && (
                <div>
                  <DivisionalSecretaryPartThreeComponent
                    ds_questions={ds_questions}
                    partThreeFields={partThreeFields}
                    partThreeSubmitted={partThreeSubmitted}
                    handlePartThreeFieldChange={handlePartThreeFieldChange}
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div
                className="btn-group mt-3"
                role="group"
                aria-label="First group"
              >
                {navigationButtonLabels.map((label, index) => (
                  <button
                    key={index} // Use a unique key for each button
                    type="button"
                    className="btn btn-secondary"
                    disabled={index + 1 === pageNumber}
                    onClick={() => handlePageChange(label)
                  }
                  >
                    Section {label}
                  </button>
                ))}
              </div>
              <div
                className="btn-group justify-content-end  mt-3"
                role="group"
                aria-label="Submit group"
              >
                {pageNumber === 1 && !partOneSubmitted && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePartOneSubmit}
                  >
                    Submit Part One
                  </button>
                )}
                {pageNumber === 2 && !partTwoSubmitted && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePartTwoSubmit}
                    disabled={!partOneSubmitted}
                  >
                    Submit Part Two
                  </button>
                )}
                {pageNumber === 3 && !partThreeSubmitted && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handlePartThreeSubmit}
                    disabled={!partOneSubmitted && !partTwoSubmitted}
                  >
                    Submit Part Three
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastMessageComponent
        headerMessage= {toastHeader}
        background = {toastBg}
        showToast={showToast}
        toastMessage={toastMessage}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
}

export default DivisionalSecretayPage;
