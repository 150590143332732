import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NavBarComponent from "../commonComponents/NavBarComponent";
import { Link } from "react-router-dom";
import WithLoaderWrapper from "../commonComponents/WithLoaderWrapper";

function DataListViewComponent() {
  const [data, setData] = useState([]);
  const [filterDate, setFilterDate] = useState(""); // New state for the filter
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    // Load data using post request when component mounts
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.post(
          "/divisionalSecretary/GetDSSummery"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatDateForFilter = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateFilterChange = (event) => {
    setFilterDate(formatDateForFilter(event.target.value));
  };

  const handleClearFilter = () => {
    setFilterDate("");
  };

  const filteredData = data.filter((item) => {
    if (!filterDate) return true; // If no date is selected, show all rows

    const formattedDate = formatDateForFilter(item.dataSubmittedOn);
    return formattedDate === filterDate;
  });

  return (
    <div>
      <NavBarComponent />
      <div className="container mt-4">
        <div className="mb-3">
          <label htmlFor="dateFilter" className="form-label">
            Filter by Data Submitted On:
          </label>
          <input
            type="date"
            id="dateFilter"
            className="form-control"
            value={filterDate}
            onChange={handleDateFilterChange}
          />
        </div>
        <div className="mb-3">
          <button
            className="btn btn-secondary"
            onClick={handleClearFilter}
            disabled={!filterDate}
          >
            Clear Filter
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Institution Name</th>
              <th>District</th>
              <th>Province</th>
              <th>Submitted By</th>
              <th>Data Submitted On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td>{index+1}</td>
                <td>{item.institutionName}</td>
                <td>{item.district}</td>
                <td>{item.province}</td>
                <td>{item.submittedByName}</td>
                <td>{formatDate(item.dataSubmittedOn)}</td>
                <td>
                  <Link
                    className="btn btn-primary"
                    to={`/view-ds-submission/${item.formReferance}`}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WithLoaderWrapper(DataListViewComponent);
