import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h1 className="card-title">Unauthorized</h1>
              <p className="card-text">You do not have access to the requested page.</p>
              <div className="d-flex justify-content-center">
              <button className="btn btn-primary" onClick={goBack}>
                  Go Back
                </button>
                <Link to="/login" className="btn btn-primary mx-3">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
