import React, { useEffect, useState } from "react";
import { Form, Pagination } from "react-bootstrap";

const KeyIndicatorsPerPage = 1; // Number of key indicators to display per page

const MCQVisualizationComponent = ({ data }) => {
  const [keyIndicatorPage, setKeyIndicatorPage] = useState(1);

  const handleKeyIndicatorPageChange = (page) => {
    setKeyIndicatorPage(page);
  };

  const indexOfLastKeyIndicator = keyIndicatorPage * KeyIndicatorsPerPage;
  const indexOfFirstKeyIndicator = indexOfLastKeyIndicator - KeyIndicatorsPerPage;
  const currentKeyIndicators = data.keyIndicators.slice(
    indexOfFirstKeyIndicator,
    indexOfLastKeyIndicator
  );

  // Calculate total score for all key indicators across all pages
  const totalKeyIndicatorsScore = data.keyIndicators.reduce((total, keyIndicator) => {
    return (
      total +
      keyIndicator.questions.reduce((questionTotal, question) => {
        return (
          questionTotal +
          question.answer.reduce((answerScore, answerIndex) => {
            return (
              answerScore +
              (answerIndex === question.options.length - 1
                ? 0 // If answerIndex is N/A, add 0 to the total score
                : answerIndex + 1)
            );
          }, 0)
        );
      }, 0)
    );
  }, 0);

  // Calculate total score for current page's key indicators
  const currentPageScore = currentKeyIndicators.reduce((total, keyIndicator) => {
    return (
      total +
      keyIndicator.questions.reduce((questionTotal, question) => {
        return (
          questionTotal +
          question.answer.reduce((answerScore, answerIndex) => {
            return (
              answerScore +
              (answerIndex === question.options.length - 1
                ? 0 // If answerIndex is N/A, add 0 to the total score
                : answerIndex + 1)
            );
          }, 0)
        );
      }, 0)
    );
  }, 0);

  return (
    <div className="container">
      {/* Display total score */}
      <div className="mt-4 d-flex justify-content-between">
        <h4>Key Indicator Score: {currentPageScore}</h4>
        <h4>Total Score: {totalKeyIndicatorsScore}</h4>
      </div>

      {currentKeyIndicators.map((keyIndicator, keyIndicatorIndex) => (
        <div key={keyIndicatorIndex} className="p-3 my-3 border">
         <h4>{keyIndicator.keyIndicatorName}</h4>
          {keyIndicator.questions.map((question, questionIndex) => (
            <Form.Group key={questionIndex} className="mb-3">
              <Form.Label>
                <div className="d-flex">
                <>{question.questionName} </>
                <b> &nbsp;&nbsp;&nbsp;&nbsp;| Score :{" "}
                {question.answer.map((answerIndex, idx) => {
                  const score =
                    answerIndex === question.options.length - 1 ? "N/A" : answerIndex + 1;
                  return (
                    <span key={idx}>
                      {score}
                      {idx < question.answer.length - 1 && ", "}
                    </span>
                  );
                })}</b>  
                </div>
                
              </Form.Label>
              <div>
                {question.options.map((option, optionIndex) => (
                  <Form.Check
                    key={optionIndex}
                    type="radio"
                    label={option}
                    name={`question-${questionIndex}`}
                    checked={question.answer.includes(optionIndex)}
                    disabled
                  />
                ))}
              </div>
            </Form.Group>
          ))}
        </div>
      ))}

      {/* Pagination */}
      <div className="d-flex justify-content-center mt-4">
        <Pagination>
         {data.keyIndicators.map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === keyIndicatorPage}
              onClick={() => handleKeyIndicatorPageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default MCQVisualizationComponent;
